import React, { useState, useEffect} from 'react';
import { Button, Form, Table, Spinner, Alert, Pagination } from 'react-bootstrap';
import axios from 'axios';
import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import { baseUrl, access_token } from '../services/api';
import { FaEdit, FaTrash } from 'react-icons/fa'; 

function AddBookTips() {
  const [formData, setFormData] = useState({
    bookId: '',
    question1: '',
    question2: '',
    question3: '',
    question4: '',
  });
  const [searchedBooks, setSearchedBooks] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBook, setSelectedBook] = useState(null); // Store the selected book object
  const [bookFile, setBookFile] = useState(null);
  const [submittedBooks, setSubmittedBooks] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false); // To show the loader
  const [errorMessage, setErrorMessage] = useState(null); // For error messages

  const [copyRightBooks, setCopyRightBooks] = useState([]); // Store books fetched from the API
  const [currentPage, setCurrentPage] = useState(1);
  const [booksPerPage] = useState(7); 
  const [isEditing, setIsEditing] = useState(false);

  // Function to fetch copyright books
  const fetchCopyRightBooks = async () => {
    try {
      console.log("Fetching data..."); // Debug: Check if function is called
      const response = await axios.get(`${baseUrl}/admin/copyRightBooks`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      console.log("API Response: ", response.data); 
      setCopyRightBooks(response.data);
      if (response.data) {
        setCopyRightBooks(response.data);
        console.log("Books fetched successfully: ", response.data); // Debug: Log success
      } else {
        setCopyRightBooks([]);
        console.warn("No data found"); // Debug: Log empty data
      }
    } catch (error) {
      console.error('Error fetching copyright books:', error.message);
    //   setError('Failed to fetch data. Please try again later.'); // Set user-friendly error message
    } finally {
      setLoading(false); // Stop loading once the request completes
    }
  };

  useEffect(() => {
    // Fetch the copyRightBooks when the component loads
    fetchCopyRightBooks();
  }, []);

  // Pagination logic: Get current books for the current page
  const indexOfLastBook = currentPage * booksPerPage;
  const indexOfFirstBook = indexOfLastBook - booksPerPage;
  const currentBooks = copyRightBooks.slice(indexOfFirstBook, indexOfLastBook);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleSearch2 = async (event) => {
    const query = event.target.value;
    setSearchTerm(query);
    if (!query) return;

    try {
      const response = await axios.get(`${baseUrl}/admin/search?query=${query}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.data.success && response.data.data) {
        const books = response.data.data.map((book) => ({
          _id: book._id,
          title: book.title,
          author: book.author || "Not Known",
          imageUrl: book.imageUrl || "",
        }));
        setSearchedBooks(books);
      } else {
        setSearchedBooks([]);
      }
    } catch (error) {
      console.error('Error searching books:', error.message);
      setSearchedBooks([]);
    }
  };

  const handleSelectBook = (bookId, book) => {
    setFormData({
      ...formData,
      bookId,
    });
    setSelectedBook(book); // Store the full book object
    setSearchTerm(book.title); 
    setSearchedBooks([]); 
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setBookFile(e.target.files[0]);
  };

  const uploadBookFile = async (bookId) => {
    try {
      setLoading(true); // Show loader
      setErrorMessage(null); // Reset error message

      const formData = new FormData();
      formData.append('file', bookFile);

      const response = await axios.post(`${baseUrl}/admin/uploadDocToOpenAi/${bookId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${access_token}`,
        },
      });

      const fileId = response.data.id;
      console.log('Received fileId:', fileId);

      if (!fileId) {
        setErrorMessage('No fileId received from the server. File might already exist.');
        throw new Error('No fileId received from the server');
      }

      return fileId;
    } catch (error) {
      setLoading(false); 
      setErrorMessage('Error uploading file. Please check for duplicate file or try again.');
      console.error('Error uploading file:', error.response ? error.response.data : error.message);
      throw error;
    }
  };

  const submitToMiniMoVectorStore = async (bookId, fileId) => {
    const miniMoPayload = {
      fileId: fileId,
      copyRightBook: true,
      prompts: [
        formData.question1,
        formData.question2,
        formData.question3,
        formData.question4,
      ],
    };

    try {
      const response = await axios.post(`${baseUrl}/admin/miniMoVectorStore/${bookId}`, miniMoPayload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
      });

      if (response.status >= 200 && response.status < 300) {
        alert('File processed and miniMoVectorStore updated successfully!');
      } else {
        alert('Oops! Something went wrong with miniMoVectorStore.');
      }
    } catch (error) {
      console.error('Error submitting to miniMoVectorStore:', error.message);
      alert('Error submitting to miniMoVectorStore. Please try again.');
    }
  };
  const handleEditButtonClick = (book) => {
    setIsEditing(true);
    
    setFormData({
      bookId: book._id, 
      question1: book.chatGptPrompt[0] || '',
      question2: book.chatGptPrompt[1] || '',
      question3: book.chatGptPrompt[2] || '',
      question4: book.chatGptPrompt[3] || '',
    });
    setSelectedBook(book);
    setSearchTerm(book.title);
  };




  const handleEditButtonClick2 = async (bookId) => {
    setLoading(true);
    try {
      const response = await axios.put(`${baseUrl}/admin/${bookId}/vector-store`, {
        chatGptPrompt: [
          formData.question1,
          formData.question2,
          formData.question3,
          formData.question4,
        ],
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        }
      });
      fetchCopyRightBooks();
      if (response.status !== 200) {
        throw new Error('Failed to update book in vector store');
      }
  
      // Handle success
      console.log("Book updated successfully:", response.data);
  
    } catch (error) {
      console.error('Error editing book:', error.message);
    } finally {
        setFormData({
            bookId: '',
            question1: '',
            question2: '',
            question3: '',
            question4: '',
          });
      setLoading(false);
      setIsEditing(false);
    }
  };
  
  


  const handleSaveButtonClick = async () => {
    setLoading(true);
    try {
      const bookId = formData.bookId;

      // Upload file
      const fileId = await uploadBookFile(bookId);
      if (!fileId) return; 

      // Submit to vector store
      await submitToMiniMoVectorStore(bookId, fileId);

      if (isEditing) {
        const updatedBooks = submittedBooks.map((book) =>
          book.bookId === formData.bookId ? { ...book, ...formData } : book
        );
        setSubmittedBooks(updatedBooks);
      } else {
        setSubmittedBooks([...submittedBooks, { ...formData, selectedBook }]);
      }

      setFormData({
        bookId: '',
        question1: '',
        question2: '',
        question3: '',
        question4: '',
      });
      setSearchTerm('');
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving book tips:', error.message);
      alert('Error occurred during the process. Please check and try again.');
    } finally {
      setLoading(false);
      fetchCopyRightBooks();
    }
  };

  const handleDeleteButtonClick = async (bookId) => {
    if (window.confirm('Are you sure you want to delete this vector store?')) {
        try {
          const response = await axios.delete(`${baseUrl}/admin/${bookId}/vector-store`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${access_token}`,
            },
          });
          fetchCopyRightBooks();
          if (response.status === 200) {
            alert(response.data.message);
            // Optionally, refresh the list or remove the deleted book from the table
            // fetchBooks();  
          } else {
            alert('Failed to delete the vector store: ' + response.data.message);
          }
        } catch (error) {
          console.error('Error deleting vector store:', error);
          alert('An error occurred while deleting the vector store: ' + error.response?.data?.message || error.message);
        }
      }
  };
  
  const filteredBooks = copyRightBooks.filter(book => {
    const hasValidPrompts = book.chatGptPrompt?.some(prompt => prompt?.trim() !== '');
    const hasFile = book.vectorStore && book.vectorStore.trim() !== '';
    return hasValidPrompts || hasFile || (!hasValidPrompts && !hasFile);
  });

  return (
    <div className="flex h-screen overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main>
          <div className="add-book-tips-page">
            <div style={{ padding: "20px" }} className="content-container">
              <h2>{isEditing? "Edit Book Tips":"Add Book Tips"} </h2>

              <div className="add-book-tips-container">
                {/* Display error message if exists */}
                {errorMessage && (
                  <Alert variant="danger">
                    {errorMessage}
                  </Alert>
                )}


               {isEditing ? "" : (<>
                <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                  {/* Search book and upload file side by side */}
                  <div className="search-container" style={{ position: 'relative' }}>
                    <Form.Group controlId="searchBook">
                      <Form.Label><strong>Search Book</strong></Form.Label>
                      <Form.Control
                        type="text"
                        value={searchTerm}
                        onChange={handleSearch2}
                        placeholder="Search for a book"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>

                    {/* Autocomplete dropdown */}
                    {searchedBooks.length > 0 && (
                      <div
                        className="autocomplete-dropdown"
                        style={{
                          position: 'absolute',
                          zIndex: 10,
                          maxHeight: '200px',
                          overflowY: 'scroll',
                          width: '100%',
                          background: '#fff',
                          border: '1px solid #ccc',
                          borderRadius: '5px',
                        }}
                      >
                        {searchedBooks.map((book) => (
                          <div
                            key={book._id}
                            className="autocomplete-item"
                            onClick={() => handleSelectBook(book._id, book)}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              padding: '10px',
                              cursor: 'pointer',
                              borderBottom: '1px solid #ddd',
                            }}
                          >
                            <img
                              src={book.imageUrl}
                              alt={book.title}
                              style={{
                                width: '40px',
                                height: '60px',
                                marginRight: '10px',
                              }}
                            />
                            <div>
                              <strong>{book.title}</strong>
                              <p style={{ margin: 0, fontSize: '12px' }}>Author: {book.author}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="file-upload-container">
                    <Form.Group controlId="bookFile">
                      <Form.Label><strong>Upload Book File</strong></Form.Label>
                      <Form.Control
                        type="file"
                        onChange={handleFileChange}
                        accept=".txt,.pdf"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>
                  </div>

                </div>
                </>)}
                <Form className="mt-4">
                  <div className="grid-container" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                    <Form.Group controlId="question1">
                      <Form.Label><strong>Question 1</strong></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="question1"
                        value={formData.question1}
                        onChange={handleInputChange}
                        placeholder="Enter answer for Question 1"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>

                    <Form.Group controlId="question2">
                      <Form.Label><strong>Question 2</strong></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="question2"
                        value={formData.question2}
                        onChange={handleInputChange}
                        placeholder="Enter answer for Question 2"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>

                    <Form.Group controlId="question3">
                      <Form.Label><strong>Question 3</strong></Form.Label>
                      <Form.Control 
                        as="textarea"
                        rows={3}
                        name="question3"
                        value={formData.question3}
                        onChange={handleInputChange}
                        placeholder="Enter answer for Question 3"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>

                    <Form.Group controlId="question4">
                      <Form.Label><strong>Question 4</strong></Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        name="question4"
                        value={formData.question4}
                        onChange={handleInputChange}
                        placeholder="Enter answer for Question 4"
                        style={{ borderRadius: '5px' }}
                      />
                    </Form.Group>
                  </div>

                  <Button
                    className="mt-4"
                    onClick={ isEditing ? () =>  handleEditButtonClick2(selectedBook._id): handleSaveButtonClick}
                    variant= {loading? "outline-info":"outline-success"}
                    style={{ borderRadius: '5px' }}
                  >
                    {loading ? (
                      <>
                        <Spinner animation="border" size="sm" /> Uploading...
                      </>
                    ) : (
                        isEditing ? 'Edit Book Tips' : 'Save Book Tips'
                    )}
                  </Button>
                </Form>
              </div>

              {loading ? (
                <Spinner animation="border" />
              ) : (
                <div style={{padding: '10px'}}>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>Book</th>
      <th>Question 1</th>
      <th>Question 2</th>
      <th>Question 3</th>
      <th>Question 4</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {filteredBooks
      .slice((currentPage - 1) * booksPerPage, currentPage * booksPerPage) // Ensure proper pagination slice
      .map((book, index) => (
        <tr key={index}>
          <td style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={book.imageUrl || '/default-book-image.jpg'}
              alt={book.title}
              style={{
                width: '40px',
                height: '60px',
                marginRight: '10px',
              }}
            />
            <div>
              <strong>{book.title}</strong>
              <p style={{ fontSize: '12px', margin: 0 }}>Author: {book.author}</p>
            </div>
          </td>

          <td>{book.chatGptPrompt[0]}</td>
          <td>{book.chatGptPrompt[1]}</td>
          <td>{book.chatGptPrompt[2]}</td>
          <td>{book.chatGptPrompt[3]}</td>

          <td>
            <Button
              onClick={() => handleEditButtonClick(book)}
              variant="outline-primary"
              size="sm"
            >
              <FaEdit />
            </Button>{' '}
            <Button
              onClick={() => handleDeleteButtonClick(book._id)}
              variant="outline-danger"
              size="sm"
            >
              <FaTrash />
            </Button>
          </td>
        </tr>
      ))}
  </tbody>
</Table>

<Pagination>
  {Array.from({ length: Math.ceil(filteredBooks.length / booksPerPage) }).map((_, index) => (
    <Pagination.Item key={index + 1} active={index + 1 === currentPage} onClick={() => paginate(index + 1)}>
      {index + 1}
    </Pagination.Item>
  ))}
</Pagination>
                                      </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default AddBookTips;
