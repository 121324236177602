import React, { useEffect } from 'react';
import {
 Routes,
   Route,
   redirect,
  useLocation
} from 'react-router-dom';
import { isAuthenticated } from './services/authService';
import './css/style.css';

import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import DashboardCard10 from './partials/dashboard/DashboardCard10';
import Users from './pages/Users';
import Login from './pages/login';
import BookTalks from './pages/BookTalks';
import SystemSettingForm from './pages/SystemSettingForm';
import AddAdminForm from './partials/actions/AddAdminForm';
import AddAdmin from './pages/AddAdmin';
import Details from './pages/Details';
import AddBadWords from './partials/actions/AddBadWord';
import AddWords from './pages/AddBadWords';
import AddFeaturedBook from './pages/AddFeaturedBooks';
import AddBookForm from './partials/actions/AddBookForm';
import AddReferralCode from './pages/AddReferralCode';
import SetUserTime from './pages/SetUserTime';
import AddFeaturedReview from './pages/AddFeaturedReview';
import AddFeaturedAuthor from './pages/AddFeaturedAuthor';
import AddFamousPerson from './pages/AddFamousPerson';
import FeaturedRecordings from './pages/FeaturedRecordings';
import AddBookTips from './pages/AddBooktips';
import PrivacyAndPolicy from './pages/PrivaceyAndPolicey';
const ProtectedRoute = ({
  redirectPath = '/',
  children,
}) => {
  if (!isAuthenticated()) {
    return <Login/>;
  }

  return children;
};

function App() {

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/dashboard" element=
        {
          <ProtectedRoute >
            <Dashboard />          
           </ProtectedRoute>
        }
        />
        <Route exact path="/allUsers" element={
                <ProtectedRoute >
                <Users/>        
               </ProtectedRoute>
        } />
        <Route exact path="/bookTalkInfo" element={
                    <ProtectedRoute >
                   <BookTalks/>       
                   </ProtectedRoute>
        } />
        <Route exact path="/systemLimitForm" element={
                    <ProtectedRoute >
                   <SystemSettingForm/>     
                   </ProtectedRoute>
        } />  
          <Route exact path="/addAdminForm" element={
                    <ProtectedRoute >
                   <AddAdmin/>     
                   </ProtectedRoute>
        } />
        <Route exact path="/addFamousPersonForm" element={
                    <ProtectedRoute >
                   <AddFamousPerson/>     
                   </ProtectedRoute>
        } />
                  <Route exact path="/addBadWords" element={
                    <ProtectedRoute >
                   <AddWords/>     
                   </ProtectedRoute>
        } />  
         <Route exact path="/reportPage/report" element={
                    <ProtectedRoute >
                      <Details/>
                   </ProtectedRoute>
        } />
                     <Route exact path="/addFeaturedBook" element={
                    <ProtectedRoute >
                      <AddFeaturedBook/>
                   </ProtectedRoute>
        } />
        <Route exact path="/addFeaturedReview" element={
                    <ProtectedRoute >
                      <AddFeaturedReview/>
                   </ProtectedRoute>
        } />
        <Route exact path="/addFeaturedAuthor" element={
                    <ProtectedRoute >
                      <AddFeaturedAuthor/>
                   </ProtectedRoute>
        } />
              <Route exact path="/addFeaturedBookForm" element={
                    <ProtectedRoute >
                      <AddBookForm/>
                   </ProtectedRoute>
        } />
         <Route exact path="/setusertime" element={
                    <ProtectedRoute >
                      < SetUserTime/>
                   </ProtectedRoute>
        } />
       
        <Route exact path="/addReferralCode" element={
                    <ProtectedRoute >
                      <AddReferralCode/>
                   </ProtectedRoute>
        } />
         <Route exact path="/featuredrecordings" element={
                    <ProtectedRoute >
                      <FeaturedRecordings/>
                   </ProtectedRoute>
        } />
         <Route exact path="/addbooktips" element={
                    <ProtectedRoute >
                      <AddBookTips/>
                   </ProtectedRoute>
        } />

<Route exact path="/privacyandpolicy" element={
                    <ProtectedRoute >
                      <PrivacyAndPolicy/>
                   </ProtectedRoute>
        } />
        
        <Route exact path="/" element={<Login/>} />
      </Routes>
    </>
  );
}

export default App;
